/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

//  Fonts
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    src: url('./assets/Poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    src: url('./assets/Poppins/Poppins-Bold.ttf');
}

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

:root {
    a {
        text-decoration-line: none;
    }

    --fa-primary-color: var(--ion-color-dark);
    --fa-secondary-color: var(--ion-color-dark);
    --fa-primary-opacity: 1;
    --fa-secondary-opacity: 0.7;

    .tab-selected {
        --fa-primary-color: var(--ion-color-primary);
        --fa-secondary-color: var(--ion-color-primary);
    }

    h1 {
        color: var(--ion-color-dark);
        font-size: 40px;
        font-weight: bold;
        line-height: 40px;
        padding: 0;
        margin: 0;
    }

    h2 {
        color: var(--ion-color-dark);
        font-size: 30px;
        font-weight: bold;
        line-height: 40px;
        padding: 0;
        margin: 0;
    }

    h3 {
        color: var(--ion-color-dark);
        font-size: 20px;
        font-weight: bold;
        padding: 0;
        margin: 0;
    }

    h4 {
        color: var(--ion-color-dark);
        font-size: 16px;
        font-weight: bold;
        padding: 0;
        margin: 0;
    }

    h5 {
        color: var(--ion-color-dark);
        font-size: 12px;
        font-weight: bold;
        padding: 0;
        margin: 0;
    }


    ion-button {
        margin: 7px auto 7px;
        font-weight: 700;
        font-size: 16px;
        height: 35px;
        // --padding-bottom: 2px;
        // --padding-top: 2px;
    }

    ion-card {
        --background: linear-gradient(-135deg, var(--ion-color-cardbg-a), var(--ion-color-cardbg-b));
        border-radius: 20px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
    }

    ion-checkbox {
        --border-color: var(--ion-color-warning);
        --size: 12px;
        margin-right: 5px;
        --border-radius: 3px;
        --background: none;
    }

    ion-content {
        --background: linear-gradient(-135deg, var(--ion-color-background-a), var(--ion-color-background-b));
    }

    ion-icon {
        margin: 0 5px;
        color: var(--ion-color-dark);
    }

    ion-item {
        --background: none;
        --border-color: var(--ion-color-dark);
        padding: 5px;
        font-size: 12px;

        img {
            margin: auto;
        }
    }

    ion-item::part(native) {
        padding: 0;
    }

    ion-item-option {
        --background: none;
    }

    ion-tab-bar {
        --background: linear-gradient(to right, var(--ion-color-tabbg-a) 25%, var(--ion-color-tabbg-b));
    }

    ion-tab-button {
        --background: transparent;
    }

    ion-toolbar {
        --background: var(--ion-color-secondary);
        --padding: 0;
    }

    ion-header ion-toolbar:first-child {
        padding-top: 0;
    }

    ion-title {
        text-align: center;
        align-items: center;
        justify-content: center;
        padding-bottom: 0;
        padding-inline: 30px;

        h1,
        h2,
        h3,
        h4,
        h5 {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .content-footer {
        margin: 10px auto 0px;
        font-size: 8px;
    }

    .content-form {
        margin: auto;
        width: 90%;
        max-width: 400px;
    }

    .form-icon {
        width: 20px;
    }

    .form-input input::placeholder {
        font-size: 12px;
    }

    .form-input {
        font-size: 12px;
    }

    .full-height-flex {
        display: flex;
        flex-flow: column;
        height: 100vh;
    }

    .full-height {
        height: 100%;
    }

    .header-img-holder {
        padding-top: calc(5px + var(--top-space-amount));
    }

    .graph-modal {
        // --border-radius: 20px 20px 0 0;
        --border-radius: 20px;
        --background: var(--ion-color-background-b)
    }

    .alert-modal {
        margin: auto;
        width: 95%;
        --border-radius: 15px;
        --max-width: 400px;
        --max-height: 300px;
        --background: var(--ion-color-background-b)
    }

    .settings-modal {
        margin: auto;
        --border-radius: 15px;
        --max-width: 400px;
        --max-height: 350px;
        --background: var(--ion-color-background-b);
    }

    .modal-content {
        --background: none;
    }

    .notification-button {
        margin-right: 13px;
        --box-shadow: none;
    }

    // .notification-popover {
    //     --box-shadow: none;
    //     --width: 90%;
    //     --max-width: 800px;
    //     --offset-x: -8px;
    //     --offset-y: -10px;

    //     ion-content {
    //         --background: var(--ion-color-tertiary);
    //     }
    // }

    .page-wrapper {
        height: calc(100% - 80px);
        max-width: 800px;
        margin: auto;
        padding: 20px 15px 15px 15px;
        height: --page-height;
        overflow: scroll;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
    }

    .page-wrapper.no-header {
        height: 100%;
    }

    .page-item-wrapper {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }

    .text-small {
        font-size: 12px;
    }

    .wallet {
        width: 90%;
        margin: auto;
        padding: 5px 0 10px 0;
        border-top: solid 1px var(--ion-color-dark);
        border-bottom: solid 1px var(--ion-color-dark);
    }

    .wallet-ballance-holder {
        height: 40px;
    }

    #card-frame {
        .yc-card-input-wrapper {
            border-radius: 5px;
            background-color: var(--ion-color-dark);
        }

        .yc-field-group {
            label {
                color: var(--ion-color-dark);
            }
        }
    }

    fa-layers-counter {
        .fa-layers-counter {
            transform: scale(.35);
        }
    }

    ion-refresher.refresher-active {
        height: 120px;
        background: linear-gradient(-135deg, var(--ion-color-background-a), var(--ion-color-background-b));
    }


    ion-modal::part(backdrop) {
        opacity: 0.8;
        background: var(--ion-color-background-a);
    }
}