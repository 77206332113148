// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
// Fonts
:root {
  --ion-font-family: 'Poppins';
  // font-family: "Poppins";
}

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #0bceda;
  --ion-color-primary-rgb: 11, 206, 218;
  --ion-color-primary-contrast: #101d1f;
  --ion-color-primary-contrast-rgb: 16, 29, 31;
  --ion-color-primary-shade: #0ab5c0;
  --ion-color-primary-tint: #23d3de;

  --ion-color-secondary: #19778d;
  --ion-color-secondary-rgb: 25, 119, 141;
  --ion-color-secondary-contrast: #f4f5f8;
  --ion-color-secondary-contrast-rgb: 244, 245, 248;
  --ion-color-secondary-shade: #16697c;
  --ion-color-secondary-tint: #308598;

  --ion-color-tertiary: #0e4c57;
  --ion-color-tertiary-rgb: 14, 76, 87;
  --ion-color-tertiary-contrast: #f4f5f8;
  --ion-color-tertiary-contrast-rgb: 244, 245, 248;
  --ion-color-tertiary-shade: #0c434d;
  --ion-color-tertiary-tint: #265e68;

  --ion-color-success: #00e689;
  --ion-color-success-rgb: 0, 230, 137;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #00ca79;
  --ion-color-success-tint: #1ae995;

  --ion-color-warning: #f9f871;
  --ion-color-warning-rgb: 249, 248, 113;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #dbda63;
  --ion-color-warning-tint: #faf97f;

  --ion-color-danger: #ff6800;
  --ion-color-danger-rgb: 255, 104, 0;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #e05c00;
  --ion-color-danger-tint: #ff771a;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #101d1f;
  --ion-color-dark-contrast-rgb: 16, 29, 31;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --ion-color-background-a: #143b40;
  --ion-color-background-a-rgb: 20, 59, 64;
  --ion-color-background-a-contrast: #ffffff;
  --ion-color-background-a-contrast-rgb: 255, 255, 255;
  --ion-color-background-a-shade: #123438;
  --ion-color-background-a-tint: #2c4f53;

  --ion-color-background-b: #101d1f;
  --ion-color-bbckground-b-rgb: 16, 29, 31;
  --ion-color-background-b-contrast: #ffffff;
  --ion-color-background-b-contrast-rgb: 255, 255, 255;
  --ion-color-background-b-shade: #0e1a1b;
  --ion-color-background-b-tint: #283435;

  --ion-color-cardbg-a: #0f6d77;
  --ion-color-cardbg-a-rgb: 15, 109, 119;
  --ion-color-cardbg-a-contrast: #ffffff;
  --ion-color-cardbg-a-contrast-rgb: 255, 255, 255;
  --ion-color-cardbg-a-shade: #0d6069;
  --ion-color-cardbg-a-tint: #277c85;

  --ion-color-cardbg-b: #0e3d49;
  --ion-color-cardbg-b-rgb: 14, 61, 73;
  --ion-color-cardbg-b-contrast: #ffffff;
  --ion-color-cardbg-b-contrast-rgb: 255, 255, 255;
  --ion-color-cardbg-b-shade: #0c3640;
  --ion-color-cardbg-b-tint: #26505b;

  --ion-color-tabbg-a: #002e39;
  --ion-color-tabbg-a-rgb: 0, 46, 57;
  --ion-color-tabbg-a-contrast: #ffffff;
  --ion-color-tabbg-a-contrast-rgb: 255, 2550, 255;
  --ion-color-tabbg-a-shade: #002832;
  --ion-color-tabbg-a-tint: #1a434d;

  --ion-color-tabbg-b: #01717c;
  --ion-color-tabbg-b-rgb: 1, 113, 124;
  --ion-color-tabbg-b-contrast: #ffffff;
  --ion-color-tabbg-b-contrast-rgb: 255, 255, 255;
  --ion-color-tabbg-b-shade: #01636d;
  --ion-color-tabbg-b-tint: #1a7f89;

  --ion-color-notificationbg-a: #29b3c2;
  --ion-color-notificationbg-a-rgb: 41, 179, 194;
  --ion-color-notificationbg-a-contrast: #000000;
  --ion-color-notificationbg-a-contrast-rgb: 0, 0, 0;
  --ion-color-notificationbg-a-shade: #249eab;
  --ion-color-notificationbg-a-tint: #3ebbc8;

  --ion-color-notificationbg-b: #2f858f;
  --ion-color-notificationbg-b-rgb: 47, 133, 143;
  --ion-color-notificationbg-b-contrast: #ffffff;
  --ion-color-notificationbg-b-contrast-rgb: 255, 255, 255;
  --ion-color-notificationbg-b-shade: #29757e;
  --ion-color-notificationbg-b-tint: #44919a;

  --col-stop: calc(100% - 15px);
  --message-height: calc(100vh - 160px);
  --ion-text-color: var(--ion-color-dark);
  --ion-background-color: none;
  --top-space-amount: 20px;

}

.ios body {
  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  :root {
    --ion-color-primary: #0bceda;
    --ion-color-primary-rgb: 11, 206, 218;
    --ion-color-primary-contrast: #101d1f;
    --ion-color-primary-contrast-rgb: 16, 29, 31;
    --ion-color-primary-shade: #0ab5c0;
    --ion-color-primary-tint: #23d3de;

    --ion-color-secondary: #19778d;
    --ion-color-secondary-rgb: 25, 119, 141;
    --ion-color-secondary-contrast: #f4f5f8;
    --ion-color-secondary-contrast-rgb: 244, 245, 248;
    --ion-color-secondary-shade: #16697c;
    --ion-color-secondary-tint: #308598;

    --ion-color-tertiary: #0e4c57;
    --ion-color-tertiary-rgb: 14, 76, 87;
    --ion-color-tertiary-contrast: #f4f5f8;
    --ion-color-tertiary-contrast-rgb: 244, 245, 248;
    --ion-color-tertiary-shade: #0c434d;
    --ion-color-tertiary-tint: #265e68;

    --ion-color-success: #00e689;
    --ion-color-success-rgb: 0, 230, 137;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #00ca79;
    --ion-color-success-tint: #1ae995;

    --ion-color-warning: #f9f871;
    --ion-color-warning-rgb: 249, 248, 113;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #dbda63;
    --ion-color-warning-tint: #faf97f;

    --ion-color-danger: #ff6800;
    --ion-color-danger-rgb: 255, 104, 0;
    --ion-color-danger-contrast: #000000;
    --ion-color-danger-contrast-rgb: 0, 0, 0;
    --ion-color-danger-shade: #e05c00;
    --ion-color-danger-tint: #ff771a;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #101d1f;
    --ion-color-dark-contrast-rgb: 16, 29, 31;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    --ion-color-background-a: #143b40;
    --ion-color-background-a-rgb: 20, 59, 64;
    --ion-color-background-a-contrast: #ffffff;
    --ion-color-background-a-contrast-rgb: 255, 255, 255;
    --ion-color-background-a-shade: #123438;
    --ion-color-background-a-tint: #2c4f53;

    --ion-color-background-b: #101d1f;
    --ion-color-bbckground-b-rgb: 16, 29, 31;
    --ion-color-background-b-contrast: #ffffff;
    --ion-color-background-b-contrast-rgb: 255, 255, 255;
    --ion-color-background-b-shade: #0e1a1b;
    --ion-color-background-b-tint: #283435;

    --ion-color-cardbg-a: #0f6d77;
    --ion-color-cardbg-a-rgb: 15, 109, 119;
    --ion-color-cardbg-a-contrast: #ffffff;
    --ion-color-cardbg-a-contrast-rgb: 255, 255, 255;
    --ion-color-cardbg-a-shade: #0d6069;
    --ion-color-cardbg-a-tint: #277c85;

    --ion-color-cardbg-b: #0e3d49;
    --ion-color-cardbg-b-rgb: 14, 61, 73;
    --ion-color-cardbg-b-contrast: #ffffff;
    --ion-color-cardbg-b-contrast-rgb: 255, 255, 255;
    --ion-color-cardbg-b-shade: #0c3640;
    --ion-color-cardbg-b-tint: #26505b;

    --ion-color-tabbg-a: #002e39;
    --ion-color-tabbg-a-rgb: 0, 46, 57;
    --ion-color-tabbg-a-contrast: #ffffff;
    --ion-color-tabbg-a-contrast-rgb: 255, 2550, 255;
    --ion-color-tabbg-a-shade: #002832;
    --ion-color-tabbg-a-tint: #1a434d;

    --ion-color-tabbg-b: #01717c;
    --ion-color-tabbg-b-rgb: 1, 113, 124;
    --ion-color-tabbg-b-contrast: #ffffff;
    --ion-color-tabbg-b-contrast-rgb: 255, 255, 255;
    --ion-color-tabbg-b-shade: #01636d;
    --ion-color-tabbg-b-tint: #1a7f89;

    --ion-color-notificationbg-a: #29b3c2;
    --ion-color-notificationbg-a-rgb: 41, 179, 194;
    --ion-color-notificationbg-a-contrast: #000000;
    --ion-color-notificationbg-a-contrast-rgb: 0, 0, 0;
    --ion-color-notificationbg-a-shade: #249eab;
    --ion-color-notificationbg-a-tint: #3ebbc8;

    --ion-color-notificationbg-b: #2f858f;
    --ion-color-notificationbg-b-rgb: 47, 133, 143;
    --ion-color-notificationbg-b-contrast: #ffffff;
    --ion-color-notificationbg-b-contrast-rgb: 255, 255, 255;
    --ion-color-notificationbg-b-shade: #29757e;
    --ion-color-notificationbg-b-tint: #44919a;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    // --ion-background-color: #000000;
    // --ion-background-color-rgb: 0,0,0;

    // --ion-text-color: #ffffff;
    // --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    // --ion-item-background: none;

    // --ion-card-background: #1c1c1d;
  }

  // .ios ion-modal {
  //   --ion-background-color: var(--ion-color-step-100);
  //   --ion-toolbar-background: var(--ion-color-step-150);
  //   --ion-toolbar-border-color: var(--ion-color-step-250);
  // }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    // --ion-background-color: #121212;
    // --ion-background-color-rgb: 18,18,18;

    // --ion-text-color: #ffffff;
    // --ion-text-color-rgb: 255,255,255;

    // --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    // --ion-item-background: none;

    // --ion-toolbar-background: #1f1f1f;

    // --ion-tab-bar-background: #1f1f1f;

    // --ion-card-background: #1e1e1e;
  }
}